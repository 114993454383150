import React from 'react';
import { AboutSlider } from './AboutSlider'

export const About = () => {

  return (
    <section id="about">
      <div className='about__wallpaper'>
        <div className='about__text-block'>
          <h1 className='about__title'>Про нас</h1>
          <div>
            <h2 className='about__description'>
              Наш автосервіс з 2010 року займається професійним ремонтом рульових механізмів всіх типів: рульові рейки,
              рульові редуктори, гідравлічні насоси, електрогідравлічні насоси.
            </h2>
          </div>
        </div>
      </div>
      <AboutSlider />
    </section>
  )
};

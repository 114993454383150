import React, { useState } from 'react';
import Modal from 'react-modal';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';

const GoogleMap = (props) => {
  const mapStyles = {
    width: '100%',
    height: '100%',
    zIndex: '20'
  };

  const customStyles = {
    content : {
      overflow: 'hidden',
      padding: '0',
      zIndex: '20',
      top: '55px',
      left: '20px',
      right: '20px'
    }
  };

  const [modalIsOpen,setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal(){
    setIsOpen(false);
  }

  return (
    <>
    <LocationIcon className='contact__title-address-icon' onClick={openModal} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='map__button-wrapper'>
          <button onClick={closeModal}>X</button>
        </div>
        <Map
          google={props.google}
          zoom={16}
          scrollwheel={false}
          style={mapStyles}
          initialCenter={{ lat: 49.437928, lng: 31.999680}}
        >
          <Marker position={{ lat: 49.437928, lng: 31.999680}} />
        </Map>
      </Modal>
    </>
  )
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCjOkPtohr7yiRMZwKhOv46yPyBtZV2oW8'
})(GoogleMap)
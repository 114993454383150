import React from 'react';
import { ReactComponent as ArrowPrev } from '../../../assets/images/arrow_left.svg';
import { ReactComponent as ArrowNext } from '../../../assets/images/arrow_right.svg';
import Slider from "react-slick/lib";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const AboutSlider = () => {
  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char = props.type === "next" ? <ArrowNext/> : <ArrowPrev/>;
    return (
      <div className={className} onClick={props.onClick}>
        {char}
      </div>
    );
  }

  const settings = {
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    responsive:
      [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1.1,
            arrows: false
          }
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 2.1,
            arrows: false
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3.1,
            arrows: false
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4.1,
            arrows: false
          }
        }
      ]
  };

  return (
    <div className='about__slider'>
      <Slider {...settings}         nextArrow={<Arrow type="next" />}
              prevArrow={<Arrow type="prev" />}>
        <span>
          Діагностика рульового механізму
        </span>
        <span>
          Ремонт рульових рейок з гідравлічним підсилювачем
        </span>
        <span>
          Ремонт рульових рейок з електричним підсилювачем
        </span>
        <span>
          Ремонт рульових редукторів
        </span>
        <span>
          Заміна рульових рейок та рульових редукторів
        </span>
        <span>
          Діагностика рульового механізму
        </span>
        <span>
          Заміна гідравлічних насосів
        </span>
        <span>
          Промивка гідравлічної системи підсилення рульового механізму
        </span>
        <span>
          Продаж насосів, рульових рейок та інших запчастин
        </span>
        <span>
          Діагностика і ремонт ходової частини (підвіски) автомобіля
        </span>
        <span>
          Комп’ютерна діагностика автомобілів
        </span>
        <span>
          Реставрація шарових з’єднань
        </span>
        <span>
          Заміна хрестовин рульових карданів
        </span>
      </Slider>
    </div>
  )
};


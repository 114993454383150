import React from 'react';

export const Service = () => {
  const dot = <span className='dot-icon'></span>;

  return (
    <section id="service">
      <div className='service__title'>
        При проведені ремонту рульових рейок та рульових редукторів у разі необхідності проводяться наступні роботи:
      </div>
      <div className='service__items'>
        <div className='service__item'>
          { dot }
          перевіряється вал на вигинання та діагностується його ремонтопридатність;
        </div>
        <div className='service__item'>
          { dot }
          шліфується та полірується вал рульових рейок, або використовується новий;
        </div>
        <div className='service__item'>
          { dot }
          контролюється розмір вала після ремонту та виводяться необхідні розміри;
        </div>
        <div className='service__item'>
          { dot }
          реставрується корпус рульових рейок, рульових редукторів методом гільзування з дотриманням усіх розмірів;
        </div>
        <div className='service__item'>
          { dot }
          у разі необхідності проводяться токарні та фрезерні роботи;
        </div>
        <div className='service__item'>
          { dot }
          міняються сальники, кольца PTFE та інші необхідні комплектуючі рульових агрегатів;
        </div>
      </div>
    </section>
  )
};

import React from 'react';
import { ReactComponent as RepairLogo1 } from '../../assets/images/services_icons/1.svg';
import { ReactComponent as RepairLogo2 } from '../../assets/images/services_icons/2.svg';
import { ReactComponent as RepairLogo3 } from '../../assets/images/services_icons/3.svg';
import { ReactComponent as RepairLogo4 } from '../../assets/images/services_icons/4.svg';
import { ReactComponent as RepairLogo5 } from '../../assets/images/services_icons/5.svg';
import { ReactComponent as RepairLogo6 } from '../../assets/images/services_icons/6.svg';
import { ReactComponent as RepairLogo7 } from '../../assets/images/services_icons/7.svg';
import { ReactComponent as RepairLogo8 } from '../../assets/images/services_icons/8.svg';
import { ReactComponent as RepairLogo9 } from '../../assets/images/services_icons/9.svg';
import { ReactComponent as RepairLogo10 } from '../../assets/images/services_icons/10.svg';
import { ReactComponent as RepairLogo11 } from '../../assets/images/services_icons/11.svg';
import { ReactComponent as RepairLogo12 } from '../../assets/images/services_icons/12.svg';

export const Repair = () => {

  const REPAIR_ITEMS = [
    {
      title: 'Діагностика рульового механізму',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo1 />
    },
    {
      title: 'Ремонт рульових рейок з гідравлічним підсилювачем',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo2 />
    },
    {
      title: 'Ремонт рульових рейок з електричним підсилювачем',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo3 />
    },
    {
      title: 'Ремонт рульових редукторів',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo4 />
    },
    {
      title: 'Заміна рульових рейок та рульових редукторів',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo5 />
    },
    {
      title: 'Заміна гідравлічних насосів',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo6 />
    },
    {
      title: 'Промивка гідравлічної системи підсилення рульового механізму',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo7 />
    },
    {
      title: 'Продаж насосів, рульових рейок та інших запчастин',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo8 />
    },
    {
      title: 'Діагностика і ремонт ходової частини (підвіски) автомобіля',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo9 />
    },
    {
      title: 'Комп’ютерна діагностика автомобілів',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo10 />
    },
    {
      title: 'Реставрація шарових з’єднань',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo11 />
    },
    {
      title: 'Заміна хрестовин рульових карданів',
      description: 'Integer turpis etiam senectus vestibulum. Enim vitae sed tincidunt ullamcorper magna\n' +
        '            massa vulputate. Netus semper congue consectetur justo netus enim, risus, id. Tellus\n' +
        '            at condimentum nullam.',
      logo: <RepairLogo12 />
    },
    ];

  const listItems = REPAIR_ITEMS.map((item) =>
    <div className='repair__item' key={ item.title }>
      <div className='repair__item-text'>
        { item.description }
      </div>
        { item.logo }
      <p className='repair__item-title'>
        { item.title }
      </p>
    </div>
  );

  return (
    <section id="repair">
      <div id='repair-anchor'></div>
      <h2 className='repair__title'>НАШІ ПОСЛУГИ</h2>
      <div className='repairs__wrapper'>
        { listItems }
      </div>
    </section>
  )
};

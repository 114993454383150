import React, { useState } from 'react';
import { ReactComponent as Phone } from '../../assets/images/phone.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

export const Navbar = () => {
  const [checked, setChecked] = useState(false);
  const toggleChecked = () => setChecked(!checked);

  return (
    <header>
      <nav role="navigation">
        <Logo className='header__logo-mobile'/>
        <div id="menuToggle">
          <label className='hidden' htmlFor="menuToggle-checkbox">M</label>
          <input type="checkbox" id="menuToggle-checkbox" checked={checked} onClick={toggleChecked}/>

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            <div className='menu-wrapper'>
            <a href="/#about" onClick={toggleChecked}><li>Про наш автосервіс</li></a>
            <a href="/#repair-anchor" onClick={toggleChecked}><li>Наші послуги</li></a>
            <a href="/#photo-anchor" onClick={toggleChecked}><li>Фото</li></a>
            <a href="/#contact" onClick={toggleChecked}><li>Контакти</li></a>
            {/*<a href="/#" onClick={toggleChecked}><li>Автомобілі та їх ремонт</li></a>*/}
            <a href="/price" onClick={toggleChecked}><li>Ціни</li></a>
            </div>
          </ul>
        </div>
      </nav>
      <a href="tel:097-330-97-70" className='header__icon-phone-wrapper'>
        t
        <Phone className='header__icon-phone'/>
      </a>

      <div>
        <a href='/'>
          <Logo className='header__logo' />
        </a>
      </div>
      <nav className='header__navigation'>
        <ul id="menu__desktop">
          <a href="/#about">
            <li>
                <span className='menu__desktop-short'>Про наc</span>
                <span className='menu__desktop-long'>Про наш автосервіс</span>
            </li>
          </a>
          <a href="/#repair">
            <li>
              <span className='menu__desktop-short'>Наші послуги</span>
              <span className='menu__desktop-long'>Наші послуги</span>
            </li>
          </a>
          <a href="/#photo">
            <li>
              <span className='menu__desktop-short'>Фото</span>
              <span className='menu__desktop-long'>Фото</span>
            </li>
          </a>
          <a href="/#contact">
            <li>
              <span className='menu__desktop-short'>Контакти</span>
              <span className='menu__desktop-long'>Контакти</span>
            </li>
          </a>
          {/*<a href="/#">*/}
          {/*  <li>*/}
          {/*    <span className='menu__desktop-short'>Ремонт</span>*/}
          {/*    <span className='menu__desktop-long'>Автомобілі та їх ремонт</span>*/}
          {/*  </li>*/}
          {/*</a>*/}
          <a href="/price">
            <li>
              <span className='menu__desktop-short'>Ціни</span>
              <span className='menu__desktop-long'>Ціни</span>
            </li>
          </a>
        </ul>
      </nav>
      <a className='header__phone'href="tel:097-330-97-70">Передзвонити</a>
    </header>
  )
}
import React from 'react';
import GoogleMap from '../Map'

export const Contacts = () => {

  return (
    <section id="contact">
      <div className='contact__about-us'>
        <h1 className='contact__title'>НАШ САЙТ</h1>
        <ul className="footer-menu">
          <a href="/#about"><li>Про наш автосервіс</li></a>
          <a href="/#repair-anchor"><li>Наші послуги</li></a>
          <a href="/#photo-anchor"><li>Фото</li></a>
          <a href="/#contact"><li>Контакти</li></a>
          {/*<a href="/#"><li>Автомобілі та їх ремонт</li></a>*/}
          <a href="/price"><li>Ціни</li></a>
        </ul>
      </div>
      <div className='contact__contacts'>
        <h1 className='contact__title'>КОНТАКТИ</h1>
        <div className='contact__contacts-body'>
          <span className='contact__title-address'>Адреса</span>
          <GoogleMap />
          <div className='contact-description'>
            місто Черкаси, Соснівський район, вулиця Руставі, 14 (біля садового центру «Оазіс»
            та житлових комплексів «Олівія», «Вишневий»)
          </div>
          <div className='contact__telephone'>
            <span className='contact__title-telephone'>Телефон</span>
            <div className='contact-telephone'>097-330-97-70</div>
          </div>
        </div>
      </div>
      <div className='contact__schedule-wrapper'>
        <div className='contact__schedule'>
          <h1 className='contact__title'>ГРАФІК</h1>
          <div className='contact__working-days'>
            <div className='contact__schedule-title'>Понеділок - П’ятниця</div>
            <div className='contact__schedule-text'>9:00 - 18:00</div>
          </div>
          <div className='contact__day-off'>
            <div className='contact__schedule-title'>Субота - Неділя</div>
            <div className='contact__schedule-text'>Вихідні</div>
          </div>
          <div className='contact__email'>
            <div className='contact__schedule-text'>Імейл</div>
            <div className='contact__schedule-title'>avtoreyka@gmail.com</div>
          </div>
        </div>
      </div>
    </section>
  )
};

import React from 'react';
import { ReactComponent as ArrowPrev } from '../../assets/images/arrow_left.svg';
import { ReactComponent as ArrowNext } from '../../assets/images/arrow_right.svg';
import Slider from "react-slick/lib";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from '../../assets/images/photo_slider/desktop/1.png';
import img2 from '../../assets/images/photo_slider/desktop/2.png';
import img3 from '../../assets/images/photo_slider/desktop/3.png';
import img4 from '../../assets/images/photo_slider/desktop/4.png';
import img5 from '../../assets/images/photo_slider/desktop/5.jpg';
import img6 from '../../assets/images/photo_slider/desktop/6.jpg';
import img7 from '../../assets/images/photo_slider/desktop/7.jpg';
import img8 from '../../assets/images/photo_slider/desktop/8.jpg';
import img9 from '../../assets/images/photo_slider/desktop/9.jpg';

export const Photo = () => {
  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char = props.type === "next" ? <ArrowNext/> : <ArrowPrev/>;
    return (
      <div className={className} onClick={props.onClick}>
        {char}
      </div>
    );
  }

  function customPaging(i) {

    return <div>{i + 1}</div>;
  }

  const settings = {
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 1500,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive:
      [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            speed: 300
          }
        },
        {
          breakpoint: 1000,
          settings: {
            customPaging: customPaging,
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false,
            speed: 700
          }
        },
        {
          breakpoint: 1400,
          customPaging: customPaging,
          settings: {
            customPaging: customPaging,
            slidesToShow: 3,
            slidesToScroll: 3,
            rows: 1
          }
        },
        {
          breakpoint: 2000,
          customPaging: customPaging,
          settings: {
            customPaging: customPaging,
            slidesToShow: 4,
            slidesToScroll: 4,
            rows: 1
          }
        }
      ]
  };

  return (
    <div className='photo__background'>
      <section id="photo">
        <div id='photo-anchor'></div>
        <h2 className='photo__title'>НАШІ ФОТО</h2>
        <Slider {...settings}
                nextArrow={<Arrow type="next" />}
                prevArrow={<Arrow type="prev" />}
                className='slider'
        >
          <div className='photo__slide-wrapper'>
            <div className='photo__slide'>
              <img src={img1} alt='service-photo' />
            </div>
          </div>
          <div className='photo__slide-wrapper'>
            <div className='photo__slide'>
              <img src={img2} alt='service-photo' />
            </div>
          </div>
          <div className='photo__slide-wrapper'>
            <div className='photo__slide'>
              <img src={img3} alt='service-photo' />
            </div>
          </div>
          <div className='photo__slide-wrapper'>
            <div className='photo__slide'>
              <img src={img4} alt='service-photo' />
            </div>
          </div>
          <div className='photo__slide-wrapper'>
            <div className='photo__slide'>
              <img src={img5} alt='service-photo' />
            </div>
          </div>
          <div className='photo__slide-wrapper'>
            <div className='photo__slide'>
              <img src={img6} alt='service-photo' />
            </div>
          </div>
          <div className='photo__slide-wrapper'>
            <div className='photo__slide'>
              <img src={img7} alt='service-photo' />
            </div>
          </div>
          <div className='photo__slide-wrapper'>
            <div className='photo__slide'>
              <img src={img8} alt='service-photo' />
            </div>
          </div>
          <div className='photo__slide-wrapper'>
            <div className='photo__slide'>
              <img src={img9} alt='service-photo' />
            </div>
          </div>
        </Slider>
      </section>
    </div>
  )
};

import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const PricePage = () => {
  const services = [
  {
    "id": 1,
    "description": "Діагностика рульового механізму на автомобілі (стандарт – 100грн., джип, мікроавтобус, складні авто  + 50 грн., техносір + 100 грн.)",
    "price": "100-250",
    "created_at": "2020-06-02T12:09:14.437Z",
    "updated_at": "2020-06-02T19:12:44.803Z"
  },
  {
    "id": 2,
    "description": "Діагностика гідравлічної рульової рейки на стенді  ",
    "price": "250",
    "created_at": "2020-06-02T12:10:56.685Z",
    "updated_at": "2020-06-02T19:13:03.866Z"
  },
  {
    "id": 3,
    "description": "Діагностика електричної рульової рейки на стенді",
    "price": "450",
    "created_at": "2020-06-02T12:11:09.023Z",
    "updated_at": "2020-06-02T19:13:32.877Z"
  },
  {
    "id": 4,
    "description": "Діагностика механічного гідравлічного насоса на стенді ",
    "price": "250",
    "created_at": "2020-06-02T12:11:20.630Z",
    "updated_at": "2020-06-02T19:13:53.691Z"
  },
  {
    "id": 5,
    "description": "Діагностика електричного гідравлічного насоса на стенді",
    "price": "450",
    "created_at": "2020-06-02T19:14:11.439Z",
    "updated_at": "2020-06-02T19:14:11.439Z"
  },
  {
    "id": 6,
    "description": "Діагностика редуктора на стенді ",
    "price": "350",
    "created_at": "2020-06-02T19:14:26.608Z",
    "updated_at": "2020-06-02T19:14:26.608Z"
  },
  {
    "id": 7,
    "description": "Регулювання рейки (стандарт – 100 грн., джип, мікроавтобус, складні авто + 50 грн., при опущенні підрамника - 450 грн.)",
    "price": "100-450",
    "created_at": "2020-06-02T19:14:52.402Z",
    "updated_at": "2020-06-02T19:14:52.402Z"
  },
  {
    "id": 8,
    "description": "Демонтаж, монтаж гідравлічної рульової рейки, (стандарт – 800 грн., джип, мікроавтобус, складні авто + від 200 - 400 грн.)",
    "price": "800 - 1200",
    "created_at": "2020-06-02T19:15:10.036Z",
    "updated_at": "2020-06-02T19:15:10.036Z"
  },
  {
    "id": 9,
    "description": "Демонтаж, монтаж механічної рульової рейки (стандарт – 600 грн., при додаткових складнощах + 200 грн.)",
    "price": "600-800",
    "created_at": "2020-06-02T19:15:28.841Z",
    "updated_at": "2020-06-02T19:15:28.841Z"
  },
  {
    "id": 10,
    "description": "Демонтаж, монтаж електричної рульової рейки (стандарт – 800 грн., джип, мікроавтобус, складні авто  +  від 200 - 400 грн.)",
    "price": "800 – 1200",
    "created_at": "2020-06-02T19:16:02.993Z",
    "updated_at": "2020-06-02T19:16:02.993Z"
  },
  {
    "id": 11,
    "description": "Демонтаж, монтаж насоса гідро-підсилювача (стандарт – 250 грн., джип, мікроавтобус, складні авто  +  від 200 - 400 грн.)",
    "price": "250-1000",
    "created_at": "2020-06-02T19:16:27.212Z",
    "updated_at": "2020-06-02T19:16:27.212Z"
  },
  {
    "id": 12,
    "description": "Ремонт гідравлічної рульової рейки робота – 1800 грн. + ремонтний комплект (800-1800) + рідина (масло) гідро підсилювача – 250 грн. + гільзування – 600 грн. +  шліфовка вала 200 грн.",
    "price": "1800 + ремкомплект + рідина +… (2850-4650)",
    "created_at": "2020-06-02T19:16:50.878Z",
    "updated_at": "2020-06-02T19:16:50.878Z"
  },
  {
    "id": 13,
    "description": "Ремонт механічної рульової рейки ",
    "price": "від 1200",
    "created_at": "2020-06-02T19:17:06.374Z",
    "updated_at": "2020-06-02T19:17:06.374Z"
  },
  {
    "id": 14,
    "description": "Ремонт електричної рульової рейки від 2000 - 4000 + ремкомплект або інші запчастини",
    "price": "2000 - 4000 + запчастини",
    "created_at": "2020-06-02T19:17:25.098Z",
    "updated_at": "2020-06-02T19:17:25.098Z"
  },
  {
    "id": 15,
    "description": "Ремонт механічного гідравлічного насоса ",
    "price": "від 500 ",
    "created_at": "2020-06-02T19:17:39.940Z",
    "updated_at": "2020-06-02T19:17:39.940Z"
  },
  {
    "id": 16,
    "description": "Ремонт електричного гідравлічного насоса",
    "price": "від 1500 ",
    "created_at": "2020-06-02T19:18:00.168Z",
    "updated_at": "2020-06-02T19:18:00.168Z"
  },
  {
    "id": 17,
    "description": "Промивка системи гідро підсилювача",
    "price": "400 + рідина для промивки",
    "created_at": "2020-06-02T19:18:18.608Z",
    "updated_at": "2020-06-02T19:18:18.608Z"
  },
  {
    "id": 18,
    "description": "Заміна шланга гідро підсилювача",
    "price": "250-500",
    "created_at": "2020-06-02T19:18:35.622Z",
    "updated_at": "2020-06-02T19:18:35.622Z"
  },
  {
    "id": 19,
    "description": "Заміна ременя гідро підсилювача",
    "price": "150-300",
    "created_at": "2020-06-02T19:19:01.718Z",
    "updated_at": "2020-06-02T19:19:01.718Z"
  },
  {
    "id": 20,
    "description": "Заміна бачка гідро підсилювача",
    "price": "150-200",
    "created_at": "2020-06-02T19:19:13.642Z",
    "updated_at": "2020-06-02T19:19:13.642Z"
  },
  {
    "id": 21,
    "description": "Демонтаж, монтаж телескопа рульової колонки",
    "price": "400-800",
    "created_at": "2020-06-02T19:19:27.287Z",
    "updated_at": "2020-06-02T19:19:27.287Z"
  },
  {
    "id": 22,
    "description": "Заміна хрестовини рульового вала",
    "price": "150",
    "created_at": "2020-06-02T19:19:41.784Z",
    "updated_at": "2020-06-02T19:19:41.784Z"
  },
  {
    "id": 23,
    "description": "Заміна рідини гідро-підсилювача",
    "price": "250",
    "created_at": "2020-06-02T19:19:52.487Z",
    "updated_at": "2020-06-02T19:19:52.487Z"
  },
  {
    "id": 24,
    "description": "Заміна рульової тяги",
    "price": "150-350",
    "created_at": "2020-06-02T19:20:02.914Z",
    "updated_at": "2020-06-02T19:20:02.914Z"
  },
  {
    "id": 25,
    "description": "Заміна рульового наконечника",
    "price": "200",
    "created_at": "2020-06-02T19:20:19.636Z",
    "updated_at": "2020-06-02T19:20:19.636Z"
  },
  {
    "id": 26,
    "description": "Реставрація телескопа рульової колонки ",
    "price": "150-250",
    "created_at": "2020-06-02T19:20:28.268Z",
    "updated_at": "2020-06-02T19:20:28.268Z"
  },
  {
    "id": 27,
    "description": "Реставрація ричагів ходової частини автомобіля",
    "price": "250-500",
    "created_at": "2020-06-02T19:20:40.466Z",
    "updated_at": "2020-06-02T19:20:40.466Z"
  },
  {
    "id": 28,
    "description": "Реставрація рульових тяг",
    "price": "200-450",
    "created_at": "2020-06-02T19:20:50.370Z",
    "updated_at": "2020-06-02T19:20:50.370Z"
  },
  {
    "id": 29,
    "description": "Реставрація рульових наконечників",
    "price": "150-600",
    "created_at": "2020-06-02T19:21:01.256Z",
    "updated_at": "2020-06-02T19:21:01.256Z"
  },
  {
    "id": 30,
    "description": "Заміна ричага ходової частини",
    "price": "230-850",
    "created_at": "2020-06-02T19:21:11.473Z",
    "updated_at": "2020-06-02T19:21:11.473Z"
  },
  {
    "id": 31,
    "description": "Заміна шарової опори",
    "price": "200-700",
    "created_at": "2020-06-02T19:21:22.019Z",
    "updated_at": "2020-06-02T19:21:22.019Z"
  },
  {
    "id": 32,
    "description": "Заміна пружини ходової частини передніх",
    "price": "300-600",
    "created_at": "2020-06-02T19:21:33.862Z",
    "updated_at": "2020-06-02T19:21:33.862Z"
  },
  {
    "id": 33,
    "description": "Заміна пружини ходової частини задніх",
    "price": "300-600",
    "created_at": "2020-06-02T19:21:45.326Z",
    "updated_at": "2020-06-02T19:21:45.326Z"
  },
  {
    "id": 34,
    "description": "Заміна амортизаторів передніх",
    "price": "300-600",
    "created_at": "2020-06-02T19:22:00.153Z",
    "updated_at": "2020-06-02T19:22:00.153Z"
  },
  {
    "id": 35,
    "description": "Заміна амортизаторів передніх",
    "price": "300-600",
    "created_at": "2020-06-02T19:22:11.047Z",
    "updated_at": "2020-06-02T19:22:11.047Z"
  },
  {
    "id": 36,
    "description": "Заміна амортизаторів задніх",
    "price": "300-600",
    "created_at": "2020-06-02T19:22:21.457Z",
    "updated_at": "2020-06-02T19:22:21.457Z"
  },
  {
    "id": 37,
    "description": "Заміна пильника + відбійника амортизаторів",
    "price": "300-600",
    "created_at": "2020-06-02T19:22:31.234Z",
    "updated_at": "2020-06-02T19:22:31.234Z"
  },
  {
    "id": 38,
    "description": "Заміна пильника шруса",
    "price": "250-600",
    "created_at": "2020-06-02T19:22:41.714Z",
    "updated_at": "2020-06-02T19:22:41.714Z"
  },
  {
    "id": 39,
    "description": "Заміна пильника рульової рейки",
    "price": "200-600",
    "created_at": "2020-06-02T19:22:52.075Z",
    "updated_at": "2020-06-02T19:22:52.075Z"
  },
  {
    "id": 40,
    "description": "Заміна втулок стабілізатора передніх",
    "price": "150-600",
    "created_at": "2020-06-02T19:23:02.316Z",
    "updated_at": "2020-06-02T19:23:02.316Z"
  },
  {
    "id": 41,
    "description": "Заміна втулок стабілізатора задніх",
    "price": "150-600",
    "created_at": "2020-06-02T19:23:13.824Z",
    "updated_at": "2020-06-02T19:23:13.824Z"
  },
  {
    "id": 42,
    "description": "Пререпресовка сайленблока",
    "price": "150-300",
    "created_at": "2020-06-02T19:23:24.466Z",
    "updated_at": "2020-06-02T19:23:24.466Z"
  },
  {
    "id": 43,
    "description": "Заміна гальмівних колодок передніх",
    "price": "200-300",
    "created_at": "2020-06-02T19:23:36.418Z",
    "updated_at": "2020-06-02T19:23:36.418Z"
  },
  {
    "id": 44,
    "description": "Заміна гальмівних колодок задніх",
    "price": "200-400",
    "created_at": "2020-06-02T19:23:46.677Z",
    "updated_at": "2020-06-02T19:23:46.677Z"
  },
  {
    "id": 45,
    "description": "Заміна гальмівних колодок задніх",
    "price": "200-400",
    "created_at": "2020-06-02T19:23:46.724Z",
    "updated_at": "2020-06-02T19:23:46.724Z"
  },
  {
    "id": 46,
    "description": "Заміна гальмівних дисків",
    "price": "250-600",
    "created_at": "2020-06-02T19:23:55.712Z",
    "updated_at": "2020-06-02T19:23:55.712Z"
  },
  {
    "id": 47,
    "description": "Заміна підшипника ступиці",
    "price": "500-800",
    "created_at": "2020-06-02T19:24:05.488Z",
    "updated_at": "2020-06-02T19:24:05.488Z"
  },
  {
    "id": 48,
    "description": "Заміна опорного підшипника",
    "price": "300-600",
    "created_at": "2020-06-02T19:24:15.809Z",
    "updated_at": "2020-06-02T19:24:15.809Z"
  }
];

  return (
    <section id="price">
      <div className='price__header-wrapper'>
        <div className='price__header'>
          <div className='price__header-description'>
            Вид роботи
          </div>
          <div className='price__header-price'>
            Ціна в грн.
          </div>
        </div>
        <ul>
          {
            services && services.map(service =>
              <li>
                <div className='price__service-description-wrapper'>
                  <div className='price__service-description'>{service.description}</div>
                </div>
                <div className='price__service-price'>{service.price}
                  <span className='price__uah'> грн.</span>
                </div>
              </li>
            )
          }
        </ul>
      </div>
    </section>
  )
};

import React, { useState } from 'react';

export const Guaranties = () => {
  const [showMore,setShowMore] = useState(false);

  return (
    <section id="guaranties">
      <div className='guaranties__text-wrapper'>
        <h2 className='guaranties__title'>Гарантія</h2>
        <p className='guaranties__text'>
          {
          !showMore && <span className='guaranties__text-short'>
            Гарантійний термін на проведені ремонтні роботи складає 6 місяців з дня встановлення нами агрегата.
            Однак дія гарантії припиняеться: при наявності механічних...
          </span>
          }
          {
            showMore && <span className='guaranties__text-short'>
            Гарантійний термін на проведені ремонтні роботи складає 6 місяців з дня встановлення нами агрегата.
            Однак дія гарантії припиняеться: при наявності механічних пошкоджень безпосередньо рульвого
            механізму та ходової частини в цілому та при втраченні щільності пильників і наявності під ними
            (вологи, піску тощо); при використанні гідравлічної рідини, яка не призначена для гідравлічної
            системи відповідного авто; при наявності в рідині інорідних частин. Слід мати на увазі, що навіть
            невеликий удар який приходиться на рульовий механізм може вивести його зі строю.
            Також необхідно пам’ятати що гідравлічну систему підсилення необхідно своєчасно обслуговувати
            у вигляді заміни рідини та нерідко ємкості для рідини, так як там може знаходитись фільтр.
          </span>
          }
          <span className='guaranties__text-long'>
            Гарантійний термін на проведені ремонтні роботи складає 6 місяців з дня встановлення нами агрегата.
            Однак дія гарантії припиняеться: при наявності механічних пошкоджень безпосередньо рульвого
            механізму та ходової частини в цілому та при втраченні щільності пильників і наявності під ними
            (вологи, піску тощо); при використанні гідравлічної рідини, яка не призначена для гідравлічної
            системи відповідного авто; при наявності в рідині інорідних частин. Слід мати на увазі, що навіть
            невеликий удар який приходиться на рульовий механізм може вивести його зі строю.
            Також необхідно пам’ятати що гідравлічну систему підсилення необхідно своєчасно обслуговувати
            у вигляді заміни рідини та нерідко ємкості для рідини, так як там може знаходитись фільтр.
          </span>
        </p>
        {
          !showMore && <a className='guaranties__text-get-more' onClick={() => setShowMore(true)}>
            Показати більше
          </a>
        }
        {
          showMore && <a className='guaranties__text-get-more' href='#guaranties'  onClick={() => setShowMore(false)}>
          Приховати
        </a>
        }
      </div>
    </section>
  )
};

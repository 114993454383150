import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Navbar } from '../Navbar'
import { About } from '../About'
import { Service } from '../Service'
import { Guaranties } from '../Guaranties'
import { Repair } from '../Repair'
import { Photo } from '../Photo'
import { Contacts } from '../Contacts'
import { PricePage } from '../../Pages/Price'
import { withTracker } from '../GoogleAnalitics';

export const Home = () => {
  const Main = () => {
    return (
      <main>
        <About />
        <Service />
        <Guaranties />
        <Repair />
        <Photo />
      </main>
    )
  };

  return (
    <Router>
      <>
        <Navbar/>
        <Switch>
          <Route path='/price' component={withTracker(PricePage)} />
          <Route path="/" component={withTracker(Main)} />
        </Switch>
        <footer>
          <Contacts />
        </footer>
      </>
    </Router>
  )
};
